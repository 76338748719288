
import { Vue, Component, Prop } from "vue-property-decorator";

export enum PageLoaderState {
  Loading,
  Completed,
}

@Component
export default class PageLoader extends Vue {
  state = PageLoaderState;
  @Prop({ default: PageLoaderState.Loading })
  pageLoaderState!: PageLoaderState;
}
