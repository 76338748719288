
import { Vue, Component } from "vue-property-decorator";
import { getTermsAndConditionsS3Path } from "@/utility/StaticFileHelper";

@Component({})
export default class TermsAndConditions extends Vue {
  acceptedTermsAndConditions = false;

  updateValue() {
    this.$emit("change");
  }

  get getTermsAndConditions(): string {
    return getTermsAndConditionsS3Path();
  }
}
