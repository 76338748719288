
import { Vue, Component } from "vue-property-decorator";

import {
  IAccountRegistration,
  ICompanyRegistration,
  IUserRegistration
} from "@/models/AccountRegistration";

import { IErrorDetails } from "@/models/ErrorDetails";
import { ISubscription } from "@/models/Subscription";

import InputField from "@/components/InputField.vue";
import Subscription from "@/components/Subscription.vue";
import PageLoader, { PageLoaderState } from "@/components/PageLoader.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import { GetSupportedLocales } from "@/utility/LanguageHelper";
import { AdminService } from "@/api/AdminServiceApi";

@Component({
  components: {
    InputField,
    Subscription,
    PageLoader,
    TermsAndConditions
  }
})
export default class DashboardCreateCompany extends Vue {
  errorDetails: IErrorDetails = {
    errors: []
  };

  pageLoaderState = PageLoaderState.Loading;

  subscription: ISubscription = {
    invoicingMethodId: 0,
    priceModelId: 0,
    subscribedRegions: [],
    subscribedCategories: [],
    discountCodeId: 0
  };

  userRegistration: IUserRegistration = {
    mobilePhone: "",
    email: "",
    password: "",
    acceptedTermsAndConditions: false,
    language: "fi"
  };

  companyRegistration: ICompanyRegistration = {
    companyName: "",
    businessCode: "",
    contactPerson: ""
  };

  selectedLanguage: string = "sv";
  subscriptionExpiryDate: string = "";
  createInvoice: boolean = false;

  get languages() {
    return GetSupportedLocales();
  }

  async registerUser() {
    this.errorDetails.errors = [];
    this.userRegistration.password = "";
    this.userRegistration.language = this.selectedLanguage;
    const accountRegistration: IAccountRegistration = {
      user: this.userRegistration,
      company: this.companyRegistration,
      subscription: this.subscription,
      subscriptionExpiryDate: this.subscriptionExpiryDate,
      createInvoice: this.createInvoice
    };

    await AdminService.RegisterCompany(accountRegistration)
      .then(async () => {
        this.$swal
          .fire({
            titleText: "Företag anslutet",
            confirmButtonText: "Ok",
            icon: "success",
            showCloseButton: true
          })
          .then(async result => {
            if (result.isConfirmed) {
              this.$router.go(0);
            }
          });
      })
      .catch(error => {
        this.errorDetails = error.response.data as IErrorDetails;
      });
  }

  loading(isLoading: boolean) {
    if (isLoading) {
      this.pageLoaderState = PageLoaderState.Loading;
    } else {
      this.pageLoaderState = PageLoaderState.Completed;
    }
  }

  setSubscription(subscription: ISubscription) {
    this.subscription = subscription;
  }

  setLanguage(language: string) {
    this.selectedLanguage = language;
  }
}
